import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  constructor(private readonly httpClient: HttpClient) {}
  getData(url: string) {
    const token = 'beartoken';
    const headers = new HttpHeaders({
      beartoken: token,
    });
    return this.httpClient.get<any>(url + '?beartoken=' + token, { headers });
  }
}

import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterOutlet } from '@angular/router';
import { AppService } from './app.service';
import { customerData } from './app.model';
import { CommonModule } from '@angular/common';
import { environment } from '../environments/environment'

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './app.component.html',
  styleUrl: './app.component.css',
})
export class AppComponent {
  defaultUrlValue1: string = `${environment.host}/customer_1/employee/E0002`;
  defaultUrlValue2: string = `${environment.host}/customer_2/employee/81646`;
  customerData1: customerData;
  customerData2: customerData;
  constructor(private readonly appService: AppService) {}
  onClickGet1() {
    this.appService
      .getData(this.defaultUrlValue1)
      .subscribe((data: customerData) => {
        this.customerData1 = data;
      });
  }
  onClickGet2() {
    this.appService
      .getData(this.defaultUrlValue2)
      .subscribe((data: customerData) => {
        this.customerData2 = data;
      });
  }
}

<div class="main">
  <div class="main__side">
    <h2>
      Customer 1
    </h2>
    <div class="main__line">
      <input class="main__input" [(ngModel)]="defaultUrlValue1" type="text">
      <button class="main__button" (click)="onClickGet1()">
        GET
      </button>
    </div>
    <input class="main__input" [ngModel]="customerData1?.customer" [readOnly]="true" type="text">
    <input class="main__input" [ngModel]="customerData1?.employee_id" [readOnly]="true" type="text">
    <textarea class="main__textarea" [ngModel]="customerData1 != null ? (customerData1.original | json) : ''"
      [readOnly]="true" [rows]="14">
    </textarea>
    <textarea class="main__textarea" [ngModel]="customerData1 != null ? (customerData1?.transformed | json) : ''"
      [readOnly]="true" [rows]="14">
    </textarea>
  </div>
  <div class="main__side">
    <h2>
      Customer 2
    </h2>
    <div class="main__line">
      <input class="main__input" [(ngModel)]="defaultUrlValue2" type="text">
      <button class="main__button" (click)="onClickGet2()">
        GET
      </button>
    </div>
    <input class="main__input" [ngModel]="customerData2?.customer" [readOnly]="true" type="text">
    <input class="main__input" [ngModel]="customerData2?.employee_id" [readOnly]="true" type="text">
    <textarea class="main__textarea" [ngModel]="customerData2 != null ? (customerData2?.original | json) : ''"
      [readOnly]="true" [rows]="14">
    </textarea>
    <textarea class="main__textarea" [ngModel]="customerData2 != null ? (customerData2?.transformed | json) : ''"
      [readOnly]="true" [rows]="14">
    </textarea>
  </div>
</div>